import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface GlobalType {
    scroll: boolean
}

const initialState: GlobalType = {
    scroll: true
}

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setScroll: (state, action: PayloadAction<boolean>) => {
            state.scroll = action.payload
        }
    }
})

export const { setScroll } = globalSlice.actions

export default globalSlice.reducer