import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MenuItemType } from "./menuSlice";

type imageType = {
    url: string,
    title: string
}
type pageType = {
    url: string,
    title: string
}

type contactsType = {
    heading: string,
    content: any[]
}

export type socialMediaType = {
    type_social: string,
    link: string,
    title: string
}

export type siteSettingsType = {
    favicon: string,
    domain: string,
    button_page: pageType | null,
    logo: imageType | null,
    footer_logo: imageType | null,
    open_graph_image: imageType | null,
    simple_page: pageType | null,
    account_page: pageType | null,
    title: string,
    organisation: string | null,
    owner_info: string,
    metrics_code: string,
    meta_code: string,
    contacts: contactsType[],
    social_media: socialMediaType[],
    footer_menu: MenuItemType[],
    address: string,
    created_at: string
}

const initialState: siteSettingsType = {
    favicon: '',
    domain: process.env.REACT_APP_BASE_URL || '',
    logo: null,
    button_page: null,
    footer_logo: null,
    open_graph_image: null,
    title: '',
    organisation: null,
    owner_info: '',
    metrics_code: '',
    meta_code: '',
    contacts: [],
    social_media: [],
    footer_menu: [],
    address: '',
    created_at: '',
    simple_page: null,
    account_page: null
}

export const siteSettingsSlice = createSlice({
    name: 'site_settings',
    initialState,
    reducers: {
        setSettings: (state, action: PayloadAction<any>) => {
            state.favicon = action.payload?.favicon
            state.logo = action.payload?.logo
            state.footer_logo = action.payload?.footer_logo
            state.button_page = action.payload?.button_page
            state.open_graph_image = action.payload?.open_graph_image
            state.simple_page = action.payload?.simple_page
            state.account_page = action.payload?.account_page
            state.title = action.payload?.title
            state.organisation = action.payload?.organisation
            state.owner_info = action.payload?.owner_info
            state.metrics_code = action.payload?.metrics_code
            state.meta_code = action.payload?.meta_code
            state.social_media = action.payload?.social_media
            state.address = action.payload?.address
            state.created_at = action.payload?.created_at

            const footer_menu: MenuItemType[] = []
            action.payload?.footer_menu.map((item: any) => {
                const footer_submenu: MenuItemType[] = []
                item.sub_menu.map((item: any) => {
                    footer_submenu.push({
                        title: item.link_title,
                        path: item.link_page ? item.link_page.url : item.link_url,
                        sub_menu: []
                    })
                })
                const menu_item = {
                    title: item.title,
                    path: item.link_page ? item.link_page.url : item.link_url,
                    sub_menu: footer_submenu
                }
                footer_menu.push(menu_item)
            })
            state.footer_menu = footer_menu

            if (action.payload?.contacts) {
                state.contacts.length = 0
                for (const contact of action.payload.contacts) {
                    state.contacts.push({
                        heading: contact.value.heading,
                        content: contact.value.content
                    })
                }

            }

        }
    }
})

export const { setSettings } = siteSettingsSlice.actions

export default siteSettingsSlice.reducer