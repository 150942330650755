import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import React from "react";

export interface RouteType {
    id: number,
    type: string,
    path: string,
    element: React.ReactNode
}

const initialState: RouteType[] = []

export const routeSlice = createSlice({
    name: 'route',
    initialState,
    reducers: {
        setRoute: (state, action: PayloadAction<RouteType[]>) => {
            state.length = 0
            for (let item of action.payload) {
                state.push(item)
            }
        }
    }
})

export const { setRoute } = routeSlice.actions

export default routeSlice.reducer