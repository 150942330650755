import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import menuReduser from "./menuSlice";
import routeReduser from "./routeSlice";
import siteSettingsReduser from "./siteSettingsSlice";
import userReduser from "./userSlice"
import globalReduser from "./globalSlice";

export const store = configureStore({
    // reducer: persistedReducer,
    reducer: {
        menu: menuReduser,
        routes: routeReduser,
        site_settings: siteSettingsReduser,
        user: userReduser,
        globalReduser
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

export const useAppDispatch: () => typeof store.dispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector